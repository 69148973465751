@use 'sass:map';
@use '~src/styles/shame/config' as config;
@use '~src/styles/theme/theme-variables' as theme;

@if (map.get(config.$toggle, 'button-focus')) {
  button:focus,
  a:focus {
    outline: 2px solid map.get(theme.$ascend-primary, 'A400');
  }
}
