@use 'sass:map';
@use '~@ascend/styles/abstracts/mixins' as mix;
@use '~src/styles/shame/config' as config;
@use '~src/styles/theme/theme-variables' as theme;

@if (map.get(config.$toggle, 'snackbar')) {
  .snackbar {
    $font-color: #fff;

    * {
      color: $font-color;
    }

    .mat-simple-snackbar-action {
      border: 1px solid rgba($font-color, 0.4);
      border-radius: 4px;
    }

    @include mix.m(success) {
      background-color: map.get(theme.$ascend-success, 500);
    }

    @include mix.m(error) {
      background-color: map.get(theme.$ascend-error, 500);
    }
  }
}
