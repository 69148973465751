@use 'sass:map';
@use '~src/styles/theme/theme-variables' as theme;

@use 'theme/theme' as theme-index;
@use 'shame/index' as shame;
@use '~@ascend/styles/ascend' as ascend;

@use '~@ascend/styles/abstracts/mixins' as mix;
@use '~@ascend/styles/abstracts/utilities' as util;

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@media (max-width: 400px) {
  .mat-stepper-horizontal > .mat-horizontal-content-container {
    padding: 0;
  }
}

h3[cdkDragHandle]:hover {
  cursor: grab;
}

ngx-material-timepicker-container {
  z-index: 1000;
  position: absolute;
}

.white-overlay {
  align-items: center;
  animation: fadeIn 0.5s ease forwards;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 3020;
}

.spinner {
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// Button Accent
button.mat-accent {
  &:not(:disabled) {
    background-color: map.get(theme.$ascend-primary, 'A400') !important;
    color: map.get(theme.$ascend-primary, 500) !important;
  }

  &.help-link-color {
    background-color: #d7960a !important;
  }
}

::ng-deep .cdk-overlay-pane .mat-menu-panel {
  max-width: 436px;
}

hr.input__divider {
  border: none;
  border-bottom: 1px solid map.get(theme.$misc-colors, 'border');
  margin: 1.3rem 0;
  width: 100%;
}

mat-label {
  color: rgba(0, 0, 0, 0.78);
}

.bankable-icon {
  font-size: 20px !important;
}

.mat-expansion-panel-body {
  box-sizing: border-box;
}

// Button Toggle - ECO / Routine / Developmental Area

// Green
mat-button-toggle.mat-button-toggle.button-toggle--routine {
  border: 1px solid rgba(map.get(theme.$i3-colors, 'greenBackground'), 0.8);
}

mat-button-toggle.mat-button-toggle.button-toggle--routine.mat-button-toggle-checked {
  background-color: rgba(map.get(theme.$i3-colors, 'greenBackground'), 0.3) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

// Blue
mat-button-toggle.mat-button-toggle.button-toggle--development {
  border: 1px solid darken(map.get(theme.$i3-colors, 'blueBackground'), 40%);
}

mat-button-toggle.mat-button-toggle.button-toggle--development.mat-button-toggle-checked {
  background-color: #90bcf9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

// Yellow
mat-button-toggle.mat-button-toggle.button-toggle--eco {
  border: 1px solid darken(map.get(theme.$i3-colors, 'yellowBackground'), 19%);
}

mat-button-toggle.mat-button-toggle.button-toggle--eco.mat-button-toggle-checked {
  background-color: rgba(255, 118, 0, 0.68) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

mat-button-toggle-group.button-toggle-group--full-width {
  width: 100% !important;
  flex-wrap: nowrap !important;
}

mat-button-toggle-group.button-toggle-group--full-width mat-button-toggle.mat-button-toggle {
  width: 100% !important;
}

.stepper__list {
  // Prevents stepper from "jumping" when changing between steps
  min-height: 74px !important;
}

.table-overflow.app-table {
  overflow-x: auto;
  @include util.scrollbar;

  table tr {
    th,
    td {
      min-width: 115px;

      @include mix.respond(1024px) {
        min-width: 175px;
      }
    }
  }
}

.mat-paginator {
  &[hidden] {
    display: none;
  }

  &.read-only {
    .mat-paginator-range-actions {
      .mat-paginator-range-label:before {
        content: 'Items:';
      }

      button {
        display: none;
      }
    }
  }
}

.white-space-pre-line {
  white-space: pre-line;
}

.calendar div.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  overflow: visible !important;
}

.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
  height: 1px;

  .fc-scroller.fc-scroller-liquid-absolute {
    overflow: hidden auto !important;
  }
}

.text-line-through {
  text-decoration: line-through !important;
}

.disabled-calendar-event {
  pointer-events: none;
  color: #000000 !important;
  background-color: #e9e7e7 !important;
}

.soundbooth-calendar-event {
  color: #ffffff !important;
  background-color: #24327b !important;

  .fc-event-title.fc-sticky {
    color: #ffffff !important;
  }
}

.screening-calendar-event,
.recheck-calendar-event {
  background: #a8c0e4 !important;
  font: #000000 !important;
}

.self-appointment-calendar-event {
  background: #f8b21c !important;
  font: #24327b !important;
}

.w-100 {
  width: 100% !important;
}

:root {
  .fc .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    max-height: none !important;

    a {
      margin-top: 0.25rem !important;
    }
  }

  .fc .fc-daygrid-dot-event .fc-event-title {
    text-wrap: wrap;
    line-height: 1rem !important;
  }

  .fc .fc-daygrid-event-dot {
    align-self: flex-start;
    margin-top: 0.3rem;
  }

  .fc .fc-scrollgrid {
    border-radius: 6px;
    box-shadow: 0 0 16px -6px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  .input-qr-header {
    display: flex;
    gap: 0.5rem;

    &__label {
      flex: 1;
      align-items: center;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 32px;
      height: 32px;

      &-icon {
        font-weight: 600;
        color: #263f8c;
        cursor: pointer;

        &--disabled {
          color: rgba(0, 0, 0, 0.12);
          cursor: default;
        }
      }
    }
  }

  .input-qr-text {
    &__wrapper {
      font-family: 'Nunito', sans-serif !important;
      width: 100%;
      max-height: 50px !important;
      overflow-y: auto !important;
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px !important;
      font-style: italic !important;
      line-height: 16px !important;

      &--hint {
        margin-top: -16px;
        margin-bottom: 8px;
      }

      &--note {
        margin-top: -16px;
        margin-bottom: 8px;
      }
    }
  }

  .user-guide-link {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    &.w-100 {
      width: 100%;
    }
  }
}

input:disabled {
  color: #00000061 !important;
}

textarea:disabled {
  color: #00000061 !important;
}
