/* 
  In SASS if an expression evaluates to false it doesn't compile so has no affect on build size.
  With that being said, still important to remove when fixing the shameful implementation.
  Things added here should eventually be moved to the Ascend.Styles package.
*/

// Toggle example:
/*
  $toggle: (
    'name-of-shameful-implementation': boolean,
  )
*/

$toggle: (
  'button-focus': true,
  'snackbar': true,
  'action': true,
);
