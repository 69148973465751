@use 'sass:map';
@use 'sass:math';
@use '~src/styles/shame/config' as config;
@use '~@ascend/styles/abstracts/mixins' as mix;

@if (map.get(config.$toggle, 'action')) {
  .action {
    @include mix.e(row) {
      $space: 0.5em;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-top: $space;

      button,
      div button,
      a[mat-raised-button],
      div a[mat-raised-button],
      a[mat-flat-button],
      div a[mat-flat-button],
      app-create-output-button {
        margin: math.div($space, 2) 0;

        &:not(:last-of-type) {
          margin-right: $space;
        }
      }

      @include mix.m(between) {
        justify-content: space-between;
      }
    }
  }
}
